import React, { useEffect, useRef } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { selectLoginedUser } from '../../redux/slices/loginedUserSlice';
import { useStyles } from './styles';
import redFlagIcon from '../../assets/images/red_flag.png';

export default function UserCard({ title, data, route }) {
  const classes = useStyles();
  const history = useHistory();
  const { isAdmin } = useSelector(selectLoginedUser);
  const emailAddressRef = useRef(null);
  const { id } = useParams();

  useEffect(() => {
    if (emailAddressRef.current) {
      const emailAddress = emailAddressRef.current.textContent;
      const mediaQuery = window.matchMedia('(min-width: 768px) and (max-width: 1200px)');
      if (mediaQuery.matches && emailAddress.length >= 30) {
        emailAddressRef.current.style.fontSize = '14px';
      }
    }
  }, []);

  const hasAdvertiser = (field) => {
    const advertiser = 'MowDSP Advertisers';

    if (field.label === advertiser && isAdmin) {
      return (
        field.value || (
          <Link className={classes.setupLink} to={`/users/${id}/info/edit?highlight=stackAdaptAdvertiser`}>
            Setup <img src={redFlagIcon} alt="red-flag" />
          </Link>
        )
      );
    }
    return field.value || 'N/A';
  };

  return (
    <Grid container direction="column" item md={6} style={{ padding: '0 25px' }}>
      <Grid container item md={12}>
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <Grid container className={classes.card} item md={12} justifyContent="space-evenly">
        {data.map((field, i) => (
          <Grid key={i} container className={classes.fieldSection} item md={12}>
            <Typography className={classes.text}>{field.label}:</Typography>
            <Box ml={1}>
              <Typography
                ref={field.label === 'Email address' ? emailAddressRef : null}
                className={classes.text}
              >
                {hasAdvertiser(field)}
              </Typography>
            </Box>
          </Grid>
        ))}
        <Grid container item md={12} justifyContent="center">
          <Button
            className={cx('button', classes.button)}
            onClick={() => history.push(route)}
            fullWidth
            type="submit"
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
